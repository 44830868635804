/*
 * @Author: your name
 * @Date: 2021-11-26 22:25:02
 * @LastEditTime: 2022-01-01 23:19:10
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \care\src\api\http.js
 */
import axios from "axios";

// 创建axios实例化
// http://192.168.100.59:3000/banner?type=3
// https://apis.netstart.cn/music
const service = axios.create({
    //baseURL:"http://localhost:9009",
    baseURL:"http:/www.yangyubiji.com/api",
    //baseURL:"api",
    timeout:30000,  //请求超时时间
    headers:{
        "Content-Type":"application/json;charset=UTF-8" //表单数据传递转化
    }
})


// request 拦截器 发送数据到后台前拦截
service.interceptors.request.use(
    config=>{
        return config;
    },
    error=>{
        console.log("axios中request报错",error);
        Promise.reject(error);
    }
)

// response 拦截器 获取后台数据前拦截
service.interceptors.response.use(
    response =>{
        return response.data;
    },
    error=>{
        console.log("axios中response报错",error);
        Promise.reject(error);
    }
)

export default service;
/*
 * @Author: your name
 * @Date: 2021-11-26 22:25:02
 * @LastEditTime: 2021-12-04 19:00:09
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \care\src\api\foster.js
 */
import axios from "./http";
import request from "./http";

/**
 * 文件列表
 * @param {*} params 
 * @returns 
 */
export function getContentList(params){
    return request({
        url:'/third/Content/list',
        method:"get",
        params
    });
}

/**
 * 视频列表
 * @param {*} params 
 * @returns 
 */
 export function getVideoList(params){
    return request({
        url:'/third/Video/list',
        method:"get",
        params
    });
}

/**
 * 用户关注列表
 * @param {*} params 
 * @returns 
 */
 export function getListAll(params){
    return request({
        url:'/third/Video/listAll',
        method:"get",
        params
    });
}

/**
 * 新增用户关注
 * @param {*} params 
 * @returns 
 */
 export function like(params){
    return request({
        url:'/third/Video/like',
        method:"post",
        params
    });
}

/**
 * 新增用户关注
 * @param {*} params 
 * @returns 
 */
 export function dislike(params){
    return request({
        url:'/third/Video/dislike',
        method:"post",
        params
    });
}




/**
 * 专家列表
 * @param {*} params 
 * @returns 
 */
export function getExpertList(params){
    return request({
        url:'/third/Expert/list',
        method:"get",
        params
    });
}

/**
 * 专家详情
 * @param {*} params 
 * @returns 
 */
 export function getExpertInfo(params){
    // return request({
    //     url:'third/Expert/info',
    //     method:"get",
    //     params
    // });
    let url = "https://www.yangyubiji.com/api/third/Expert/info"
    return axios.get(url,{params});
   
}



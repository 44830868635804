import Vue from 'vue'
import VueRouter from 'vue-router'
import index from "../views/index.vue";  //同步路由加载
import guidePage from "../components/public/guidePage.vue";  //同步路由加载

Vue.use(VueRouter)
 
const routes = [
  {
    path: '/',
    redirect:"/index"
  },
  {
    path:'/login',
    name:"login",
    component:()=>import("../components/base/login.vue")
  },
  {
    path:'/userInfo',
    name:"userInfo",
    component:()=>import("../views/userInfo.vue"),
    // 独享路由守卫
    // beforeEnter: (to, from, next) => {
    //   let login = window.localStorage.getItem("login");
    //   // 判断登录情况
    //   if(!login){
    //     next("/login");
    //     return 
    //   }else{
    //     next();
    //   }
    // }
  },
  {
    path:"/guidePage",
    name:"guidePage",
    component:guidePage
  },
  {
    path:"/index",
    name:"index",
    // 添加路由元信息
    meta:{
        headerNav:true
    },
    component:index
  },
  {
    path:"/singer",
    name:"singer",
    meta:{
        headerNav:true
    },
    component:()=>import("../views/singer.vue")  //懒加载
  },
  {
    path:"/singer/singerlist",
    name:"singerlist",
    component:()=>import("../components/singer/singerlist.vue")  //懒加载
  },
  {
    path:"/rank",
    name:"rank",
    meta:{
        headerNav:true
    },
    component:()=>import("../views/rank.vue")
  },
  {
    path:"/rank/ranklist",
    name:"ranklist",
    component:()=>import("../components/rank/ranklist.vue")
  },
    // MV 视频 
  {
    path:"/newmv",
    name:"newmv",
    meta:{
        headerNav:true,
        // player:false
    },
    component:()=>import("../views/newmv.vue")
  },

  // 搜索
  {
    path:"/search",
    name:"search",
    component:()=>import("../components/search/search.vue")
  },
  {
    path:"/search/searchlist",
    name:"searchlist",
    component:()=>import("../components/search/searchlist.vue")
  },
  // 登录页面
  {
    path: '/login',
    name: "login",
    component: () => import("../components/base/login.vue")
  },
  // 个人中心
  {
    path: '/userInfo',
    name: "userInfo",
    meta: {
      player: true
    },
    component: () => import("../views/userInfo.vue"),
    // 独享路由守卫
    // beforeEnter: (to, from, next) => {
    //   let login = window.localStorage.getItem("login");
    //   // 判断登录情况
    //   if(!login){
    //     next("/login");
    //     return 
    //   }else{
    //     next();
    //   }
    // }
  },
  // 引导页
  {
    path: "/guidePage",
    name: "guidePage",
    component: guidePage,
    meta: {
      headerNav: true,
      player: false
    },
  },
  // 推荐页面
  {
    path: "/recommend",
    name: "recommend",
    // 添加路由元信息
    meta: {
      headerNav: true,
      player: true
    },
    component: () => import("../views/recommend.vue") 
  },
  // 歌手页面
  {
    path: "/singer",
    name: "singer",
    meta: {
      headerNav: true,
      player: true
    },
    component: () => import("../views/singer.vue")  //懒加载
  },
  // 歌手单曲页面
  {
    path: "/singer/singerlist",
    name: "singerlist",
    meta: {
      player: true
    },
    component: () => import("../components/singer/singerlist.vue")  //懒加载
  },
  // 排行榜页面
  {
    path: "/rank",
    name: "rank",
    meta: {
      headerNav: true,
      player: true
    },
    component: () => import("../views/rank.vue")
  },
  // 排行榜单曲页面
  {
    path: "/rank/ranklist",
    name: "ranklist",
    meta: {
      player: true
    },
    component: () => import("../components/rank/ranklist.vue")
  },
  // MV 视频 页面
  {
    path: "/newmv",
    name: "newmv",
    meta: {
      headerNav: true,
      player: false
    },
    component: () => import("../views/newmv.vue")
  },

  // 搜索页面
  {
    path: "/search",
    name: "search",
    component: () => import("../components/search/search.vue")
  },
  // 搜索结果页面
  {
    path: "/search/searchlist",
    name: "searchlist",
    meta: {
      player: true
    },
    component: () => import("../components/search/searchlist.vue")
  },
  // 评论页面
  {
    path: "/pinglun/pinglundata",
    name: "pinglundata",
    component: () => import("../components/pinglun/pinglundata.vue")
  },
  {
    path:"/pinglun/pinglundata",
    name:"pinglundata",
    component:()=>import("../components/pinglun/pinglundata.vue")
  },
  {
    path:"/song/songlist",
    name:"songlist",
    component:()=>import("../components/song/songlist.vue")
  },
  {
    path:"/doctor",
    name:"doctor",
    component:()=>import("../views/doctor.vue")
  },
  // 每日一信openid
  {
    path: "/daily",
    name: "daily",
    component: () => import("../views/daily.vue")
  },
  // 每日一信
  {
    path: "/dailymsg",
    name: "dailymsg",
    component: () => import("../views/dailymsg.vue")
  },
  {
    path: "/test",
    name: "test",
    component: () => import("../views/test.vue")
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to,from,next)=>{
//   let login = window.localStorage.getItem("login");
//   // 登录界面
//   if(to.path == "/login"){
//     next();
//     return;
//   }else{
//     // 判断登录情况
//     if(!login){
//       next("/login");
//       return;
//     }else{
//       next();
//     }
//   }
// })

export default router

/*
 * @Author: your name
 * @Date: 2021-11-26 22:25:02
 * @LastEditTime: 2022-01-02 21:26:19
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \care\src\main.js
 */
import Vue from 'vue'
import vant from "vant"
import App from './App.vue'
import router from './router'
import store from './store'

import Video from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$video = Video

Vue.config.productionTip = false

// $cnpm i vant -S
import "vant/lib/index.css"
Vue.use(vant);
// 字体图标
import "./assets/font/iconfont.css"
import "./assets/font1/iconfont.css"

// $cnpm i axios -S

// 仅仅只是VueScroller需要全局注册
import VueScroller from 'vue-scroller'
Vue.use(VueScroller)

import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload)

import { List } from 'vant';
Vue.use(List);


// import MamicPlayer from 'vue-mamicplayer'

// import dragBall from 'vue-drag-ball'
// Vue.use(dragBall)

import Vconsole from 'vconsole';
let vConsole = new Vconsole();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
